/** @format */
/*#00004d  #ff6600*/
.home-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-image: url('./../images/homebg10.png');
  /* background-image: linear-gradient(
      rgba(77, 73, 73, 0.521),
      rgba(54, 56, 54, 0.493)
    ),
    url('./../images/homebg10.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.home-text {
  display: inline-block;
  margin-left: 50%;
  margin-top: 150px;
  transform: translateX(-50%);
  padding: 20px;
  background-color: rgba(67, 99, 89, 0.37);
  border: 3px solid white;
}
.btn-back {
  background-color: #00004d;
}

.modal-custom {
  overflow-y: scroll;
  height: 450px;
}
.btn-set {
  background-color: #00004d;
  color: white;
}
.overlay-custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-color: #2727295e;
  z-index: 100;
}
.custom-modal {
  position: absolute;
  width: 500px;
  height: 600px;
  background-color: #00004d;
  border-radius: 4px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.position-btn {
  /* position: absolute; */
  margin-left: 50%;
  transform: translate(-50%);
  z-index: 10;
}
.profile-img {
  position: absolute;
  top: 66px;
  left: 50%;
  transform: translate(-50%);
}
.custom-alert {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1030;
}

@media (max-width: 520px) {
  .custom-modal {
    position: absolute;
    width: 100%;
    height: 600px;
    background-color: #00004d;
    border-radius: 4px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
}
