.card-custom2 {
  position: fixed;
  top: 10px;
  bottom: 10px;
  left: 20%;
  width: 60%;
}
.big-img {
  width: 350px;
}
.small-img {
  width: 200px;
}
@media (max-width: 992px) {
  .card-custom2 {
    top: 10px;
    bottom: 10px;
    left: 0%;
    width: 100%;
  }
}
