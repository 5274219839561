/** @format */

.choise-bg {
  background-color: rgba(17, 17, 17, 0);
}
.bg-container {
  background-color: rgba(228, 223, 222, 0.884);
}
.finish-match {
  z-index: 10;
  background-color: rgba(18, 19, 19, 0.575);
}
.overflowh {
  width: 100%;
  overflow-x: scroll;
  height: 90px;
}
